/* eslint-disable import/prefer-default-export */

import { useLazyQuery, useQuery } from '@apollo/client';
import { cloneAndRemoveTypename } from 'common/utils';
import { v4 as uuid } from 'uuid';
import { GET_TEMPLATES } from 'components/TemplatesModel/graphql/Queries';
import { TEMPLATE_MODELS } from 'components/TemplatesModel/utils/constants';
import { useAppContext } from 'AppContext';
import { useMemo } from 'react';

export const useTemplateData = () => {
  const { setTemplates } = useAppContext();

  const [getTemplates, states] = useLazyQuery(GET_TEMPLATES, {
    variables: {
      pagination: {
        skip: 0, // Start from the beginning
        limit: 'max', // Default to fetching all items
      },
    },

    fetchPolicy: 'network-only',
    onCompleted(data) {
      setTemplates(cloneAndRemoveTypename(data?.templates?.data));
    },
    onError() {
      // Handle error (optional)
    },
  });

  // This will return the function to trigger the query and the data/loader state
  return [
    getTemplates, // This is the function to trigger the lazy query
    states,
  ];
};

export const useGetTemplates = () => {
  const {
    state: { templates },
  } = useAppContext();

  // Memoize TEMPLATE_MODELS_CLONE to prevent re-creating it on every render
  const TEMPLATE_MODELS_CLONE = useMemo(
    () =>
      [...TEMPLATE_MODELS].map((t) => ({
        ...t,
        id: uuid(),
      })),
    [],
  ); // Empty array ensures that uuid() is only called once when the component mounts

  useMemo(() => {
    templates?.forEach((template) => {
      // Loop through all the indices where the templateId matches
      TEMPLATE_MODELS_CLONE.forEach((model, index) => {
        if (model.template.templateId === template.templateId) {
          TEMPLATE_MODELS_CLONE[index].template = template;
          TEMPLATE_MODELS_CLONE[index].templateId = template.id;
        }
      });
    });
  }, [templates, TEMPLATE_MODELS_CLONE]);

  return { data: TEMPLATE_MODELS_CLONE, loading: false };
};

export const TEMPLATE_ORDER = [
  '1',
  '13',
  '16',
  '5',
  '6',
  '9',
  '10',
  '23',
  '26',
  '24',
  '15',
  '17',
  '18',
  '19',
  '21',
  '26',
  '14',
  '30',
  '32',
  '33',
  '28',
  '29',
  '31',
  '35',

  '40',
  '41',
  '8',
  '5-1',
  '5-2',
  '5-3',
  '5-4',
  '2-1',
  '2-2',
  '2-3',
  '2-4',
  '2-5',
  '3-1',
  '3-2',
  '3-3',
  '20-1',
  '6-4',

  '2',
  '3',
  '4',
  '7',
  '20',
  '22',
  '25',
  '27',
  '11',
  '12',
  '36',
  '37',
  '38',
  '39',
  '3-4',
  '3-5',
  '7-1',
  '22-1',
  '22-2',
  '22-3',
  '6-1',
  '6-2',
  '6-3',

  '4-1',
  '13-2',
  '24-1',
  '24-2',
  '24-3',
  '24-4',
  '24-5',
  '17-1',
  '18-1',
  '21-1',
  '33-1',
  '33-2',
  '8-1',
  '8-2',
  '13-1',
  '14-1',
];

// export const TEMPLATE_ORDER = [
//   '1',
//   '2',
//   '3',
//   '4',
//   '5',
//   '6',
//   '7',
//   '8',
//   '9',
//   '10',
//   '11',
//   '12',
//   '13',
//   '14',
//   '15',
//   '16',
//   '17',
//   '18',
//   '19',
//   '20',
//   '21',
//   '22',
//   '23',
//   '24',
//   '25',
//   '26',
//   '27',
//   '28',
//   '29',
//   '30',
//   '31',
//   '32',
//   '33',
//   '34',
//   '35',
//   '36',
//   '37',
//   '38',
//   '39',
// ];

export const TEMPLATE_ORDER_OLD = [
  1, 13, 14, 16, 5, 2, 3, 7, 20, 22, 6, 4, 9, 10, 24, 15, 17, 18, 19, 21, 26,
  27, 25, 30, 32, 33, 8,
];

export const useFilteredTemplates = () => {
  // Fetch template data using useGetTemplates hook
  const { data, loading } = useGetTemplates();

  const filterAndSortTemplates = (data) => {
    if (!data) return [];

    const templateIdToIndexMap = TEMPLATE_ORDER.reduce(
      (acc, templateId, index) => {
        acc[templateId] = index;
        return acc;
      },
      {},
    );

    const filteredAndSortedData = data
      .filter((template) => {
        const isIncluded = TEMPLATE_ORDER.includes(template.name);
        return isIncluded;
      })
      .sort((a, b) => {
        const indexA = templateIdToIndexMap[a.name];
        const indexB = templateIdToIndexMap[b.name];
        return indexA - indexB;
      });

    return filteredAndSortedData;
  };

  // Return loading status and filtered data
  return {
    templates: filterAndSortTemplates(data),
    loading,
  };
};
