/* eslint-disable no-console */
/* eslint-disable no-undef */
import { useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from './use-toast';
import useSentryCapture from './sentry';

export const redirectUri =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://app.syncsignature.com'
    : 'https://app.dev.syncsignature.com';

const cookies = new Cookies();

const loadGoogleAPI = () =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('client', resolve);
    };
    document.body.appendChild(script);
  });

const useGoogleWorkspaceUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { captureError } = useSentryCapture();

  const scopes = [
    'https://www.googleapis.com/auth/admin.directory.user.readonly',
  ].join(' ');

  const clearToken = () => {
    cookies.remove('access_token', { path: '/' });
  };

  // const listGoogleWorkspaceUsers = (token, modelRef) => {
  //   // window.gapi.client.setToken({ access_token: token });
  //   window.gapi.client.directory.users
  //     .list({
  //       customer: 'my_customer',
  //       maxResults: 100,
  //       orderBy: 'email',
  //     })
  //     .then((response) => {
  //       const { users } = response.result;
  //       const usersWithDetails = users.map((user) => ({
  //         id: user.id,
  //         name: user?.name?.fullName,
  //         email: user.primaryEmail,
  //         position: user?.organizations?.[0]?.title || '',
  //         department: user?.organizations?.[0]?.department || '',
  //         phone: user?.phones?.[0]?.value || '',
  //         profileImage: null,
  //         aliases: user.aliases || [], // User Aliases
  //         domainAliases: user.nonEditableAliases || [], // Domain Aliases
  //       }));

  //       setUsers(usersWithDetails);
  //       modelRef.current.open();
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       if ([403, 400].includes(error?.result?.error?.code)) {
  //         toast({
  //           description:
  //             'You need to be an admin of the Google Workspace to activate this integration.',
  //           closeicn: 'destructive',
  //         });
  //       }
  //       console.error('Error fetching users:', error);
  //     });
  // };

  const listGoogleWorkspaceUsers = async (token, modelRef) => {
    if (!token) {
      console.error('Google API client not initialized.');
      toast({
        description:
          'Google API client not initialized. Please Authenticate and try again.',
        closeicn: 'destructive',
      });
      return null;
    }
    setLoading(true);

    try {
      const response = await axios.get(
        'https://admin.googleapis.com/admin/directory/v1/users',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
          params: {
            customer: 'my_customer',
            maxResults: 100,
            orderBy: 'email',
          },
        },
      );

      const { users } = response.data;

      const usersWithDetails = users.map((user) => ({
        id: user.id,
        name: user?.name?.fullName,
        email: user.primaryEmail,
        position: user?.organizations?.[0]?.title || '',
        department: user?.organizations?.[0]?.department || '',
        phone: user?.phones?.[0]?.value || '',
        profileImage: null,
        aliases: user.aliases || [], // User Aliases
        domainAliases: user.nonEditableAliases || [], // Domain Aliases
      }));

      setUsers(usersWithDetails);
      modelRef.current.open();
    } catch (error) {
      if ([403, 400].includes(error?.response?.status)) {
        toast({
          description:
            'You need to be an admin of the Google Workspace to activate this integration.',
          closeicn: 'destructive',
        });
      }
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGoogleWorkspaceUserPhoto = async (token, userKey) => {
    if (!token) {
      console.error('Google API client not initialized.');
      toast({
        description: 'Google API client not initialized. Please try again.',
        closeicn: 'destructive',
      });
      captureError(error, { feature: 'google-workspace-user-photo' });
      return null;
    }
    setLoading(true);

    try {
      const response = await axios.get(
        `https://admin.googleapis.com/admin/directory/v1/users/${encodeURIComponent(userKey)}/photos/thumbnail`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      );

      if (response?.data?.photoData) {
        // Sanitize and fix the Base64 string
        let base64String = response.data.photoData
          .replace(/_/g, '/')
          .replace(/-/g, '+');

        // Ensure proper Base64 padding
        const missingPadding = base64String.length % 4;
        if (missingPadding) {
          base64String += '='.repeat(4 - missingPadding);
        }

        // Build the data URI
        const mimeType = response.data.mimeType || 'image/jpeg';
        const base64Image = `data:${mimeType};base64,${base64String}`;

        return { base64Image, mimeType };
      }
      toast({
        description: 'No photo data available for this user.',
        closeicn: 'destructive',
      });
      captureError(error, { feature: 'google-workspace-user-photo' });
      return null;
    } catch (error) {
      console.error('Error fetching user photo:', error);
      if (error?.response?.status === 403) {
        toast({
          description:
            'You need admin privileges to fetch user photos. Please check your permissions.',
          closeicn: 'destructive',
        });
      } else {
        // toast({
        //   description: 'Failed to fetch user photo. Please try again later.',
        //   closeicn: 'destructive',
        // });
      }
      captureError(error, { feature: 'google-workspace-user-photo' });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleFetchGoogleWorkspaceUsers = async () => {
    clearToken();

    const setAccessTokenCookie = (token) => {
      cookies.set('access_token', token, {
        path: '/', // Accessible across the app
        secure: true, // Only on HTTPS
        sameSite: 'Strict', // Prevent CSRF
        maxAge: 3600, // Token valid for 1 hour
      });
    };

    const handleCredentialResponse = async (response) => {
      if (response.credential) {
        return new Promise((resolve, reject) => {
          const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: clientId,
            scope: scopes,
            prompt: 'consent',
            callback: (tokenResponse) => {
              if (tokenResponse.error) {
                console.error('Error obtaining token:', tokenResponse.error);
                reject(tokenResponse.error);
              } else {
                setAccessTokenCookie(tokenResponse.access_token);
                resolve(tokenResponse.access_token);
              }
            },
            ux_mode: 'redirect',
            redirect_uri: redirectUri,
          });
          tokenClient.requestAccessToken();
        });
      }
    };

    try {
      await loadGoogleAPI();

      await window.gapi.client.init({
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest',
        ],
      });

      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
      });

      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      let accessToken = null;

      if (code) {
        accessToken = await handleCredentialResponse({ credential: code });
      } else {
        accessToken = await new Promise((resolve, reject) => {
          const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: clientId,
            scope: scopes,
            prompt: 'consent',
            callback: (tokenResponse) => {
              if (tokenResponse.error) {
                console.error('Error obtaining token:', tokenResponse.error);
                reject(tokenResponse.error);
              } else {
                setAccessTokenCookie(tokenResponse.access_token);
                resolve(tokenResponse.access_token);
              }
            },
            ux_mode: 'redirect',
            redirect_uri: redirectUri,
          });
          tokenClient.requestAccessToken();
        });
      }

      if (accessToken) {
        console.log('Access Token:', accessToken);
        return accessToken;
        // listGoogleWorkspaceUsers(accessToken, modelRef);
      }
    } catch (error) {
      console.error('Error in Google Workspace user fetch flow:', error);
    }
  };

  return {
    users,
    loading,
    listGoogleWorkspaceUsers,
    handleFetchGoogleWorkspaceUsers,
    fetchGoogleWorkspaceUserPhoto,
  };
};

export default useGoogleWorkspaceUsers;
