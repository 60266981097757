/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirtyNine({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);

  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const socialLink = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.value)].includes(field.value),
  );

  const allUrlsAreNull =
    !getFieldData(fields, 'companyLogo') &&
    !getFieldData(fields, 'companyName') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');
  // const contentWidth = get(design, 'styles.content.style.width', 600);

  const contentWidth = '1000';

  const backgroundImage =
    'url("https://static.dev.sendsig.com/asset/Template39_bg.png")'; // Static background image
  const backgroundSize = 'auto'; // Static background size
  const backgroundPosition = 'left top'; // Static background position
  const backgroundRepeat = 'no-repeat'; // Static background repeat
  const backgroundOrigin = 'border-box'; // Static background origin

  const backgroundStyle = {
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    backgroundOrigin,
  };

  return (
    <>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td>
            <table cellPadding="0" cellSpacing="0" border="0">
              {getFieldData(fields, 'companyLogo') && (
                <tr>
                  {CompanyLogo({
                    data: getFieldData(fields, 'companyLogo'),
                    design,
                    style: {},
                  })}
                </tr>
              )}
            </table>
          </td>
          <td>
            <table cellPadding="0" cellSpacing="0" border="0">
              {getFieldData(fields, 'headshotUrl') && (
                <tr align="left">
                  <HeadshotImage
                    data={getFieldData(fields, 'headshotUrl')}
                    design={design}
                    align="left"
                    style={{
                      paddingBottom: '8px',
                    }}
                  />
                </tr>
              )}
            </table>
          </td>
        </tr>
      </table>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          ...backgroundStyle,
          fontFamily: font,
          userSelect: 'none',
          padding: '10px',
        }}
      >
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0px"
              cellSpacing="0"
              border="0"
              ref={mainContentRef}
              style={{
                paddingBottom: '35px',
              }}
            >
              <tr>
                <td
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 0,
                    lineHeight: '100%',
                    verticalAlign: 'top',
                    paddingBottom: '20px',
                    paddingLeft: '30em',
                  }}
                >
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    style={{
                      borderCollapse: 'collapse',
                    }}
                  >
                    {getFieldData(fields, 'name') && (
                      <tr>
                        {Name({
                          signature,
                          data: getFieldData(fields, 'name'),
                          style: {
                            fontFamily: 'inherit',
                            paddingTop: '40px',
                            fontSize: '28px',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'position') && (
                      <tr>
                        {Position({
                          signature,
                          data: getFieldData(fields, 'position'),
                          style: {
                            fontFamily: 'inherit',
                            fontSize: '18px',
                            fontWeight: 500,
                            fontStyle: 'italic',
                            paddingTop: '5px',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'department') && (
                      <tr>
                        {Department({
                          signature,
                          data: getFieldData(fields, 'department'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'phone') && (
                      <tr>
                        {Phone({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'phone'),
                          style: {
                            fontFamily: 'inherit',
                            fontSize: '16px',
                            fontWeight: 600,
                            fontStyle: 'italic',
                            paddingTop: '30px',
                            color: '#c00000',
                          },
                          customWord: 'Tel:',
                          customColor: '#000000',
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'email') && (
                      <tr>
                        {Email({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'email'),
                          style: { color: '#c00000' },
                          customColor: '#000000',
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyAddress') && (
                      <tr>
                        {CompanyAddress({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyAddress'),
                          style: { color: '#c00000' },
                        })}
                      </tr>
                    )}
                    {!!socialFields?.length && (
                      <tr>
                        {SocialLinks({
                          signature,
                          showAwsIcons,
                          data: socialFields,
                          design,
                          style: {},
                        })}
                      </tr>
                    )}
                  </table>
                </td>
                <td style={{ paddingLeft: '10em' }}>
                  <AddOns
                    signature={signature}
                    design={design}
                    hideBranding={hideBranding}
                    showAwsIcons={showAwsIcons}
                    order={['SocialBanners']}
                    alignment="vertical"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
      >
        <tr>
          <td
            align="center"
            verticalAlign="center"
            style={{
              backgroundColor: design?.primaryBrandColor,
            }}
          >
            <table>
              {getFieldData(fields, 'companyName') && (
                <tr align="center">
                  {CompanyName({
                    signature,
                    data: getFieldData(fields, 'companyName'),
                    style: {
                      fontFamily: 'inherit',
                      fontWeight: 700,
                      fontSize: '16px',
                    },
                  })}
                </tr>
              )}
              {getFieldData(fields, 'website') && (
                <tr align="center">
                  {Website({
                    signature,
                    showAwsIcons,
                    design,
                    data: getFieldData(fields, 'website'),
                    style: {},
                  })}
                </tr>
              )}
              {getFieldData(fields, 'companyEmail') && (
                <tr align="center">
                  {CompanyEmail({
                    signature,
                    showAwsIcons,
                    design,
                    data: getFieldData(fields, 'companyEmail'),
                    style: {},
                  })}
                </tr>
              )}
              {getFieldData(fields, 'companyPhone') && (
                <tr align="center">
                  {CompanyPhone({
                    signature,
                    showAwsIcons,
                    design,
                    data: getFieldData(fields, 'companyPhone'),
                    style: {},
                  })}
                </tr>
              )}
            </table>
          </td>
        </tr>
      </table>
      <table
        width={`${contentWidth}px`}
        cellPadding="0"
        cellSpacing="0"
        border="0"
      >
        <tr>
          <td
            align="center"
            verticalAlign="center"
            style={{
              backgroundColor: design?.primaryBrandColor,
            }}
          >
            {' '}
            <AddOns
              signature={signature}
              design={design}
              hideBranding={hideBranding}
              showAwsIcons={showAwsIcons}
              order={['CallToActionBanner', 'CallToAction', 'Footer']}
              styles={{
                textAlign: 'center',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            />
          </td>
        </tr>
      </table>
    </>
  );
}

export default TemplateStyleThirtyNine;
